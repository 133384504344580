import { useState, useEffect } from "react";
import { useParams, useNavigate, Link } from "react-router-dom";
import Decline from "../../helpers/DeclentionHelper";
import { isTokenExpired } from "../../helpers/JwtHelper";
import authService from "../../services/authService";
import patternService from "../../services/patternService";
import pictureService from "../../services/pictureService";
import PatternFullModel from "../../types/api/PatternFullModel";
import PriceFilterModel from "../../types/PriceFilterModel";
import Button from "../ui/Button";
import styles from "./DetailedMockup.module.css";

const DetailedMockup = () => {
  const params = useParams();
  const navigate = useNavigate();

  const mockupId = params.id;
  if (!mockupId) {
    navigate("/");
  }

  const [data, setData] = useState<PatternFullModel>();
  const [showAuthMessage, setShowAuthMessage] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    const getTypes = async () => {
      const mockup = await patternService.getFullPattern(mockupId!);
      mockup.pictures.sort((a,b) => a.order - b.order)
      setData(mockup);
      setIsLoading(false);
      if (!authService.authenticated()) {
        setShowAuthMessage(true);
      }
    };

    getTypes().catch((e) => {
      console.error(e);
      navigate("/");
    });
  },[]);

  const handleDownloadFile = async() => {
    if(data == null)
      return;
    setIsLoading(true);
    await patternService.getPatternFile(data.id, data.name);
    setIsLoading(false);
  };

  return (
    <div className={styles.container}>
      <div className={styles.images}>
        {data?.pictures.map((picture) => (
          <img
            className={styles.image}
            key={picture.largeUrl}
            src={pictureService.getPictureUrl(picture.largeUrl)}
            alt="Изображение мокапа"
          ></img>
        ))}
      </div>
      <div className={styles["info-container"]}>
        <div className={styles.info}>
          <div className={styles.categories}>
            {data?.productTypes.map((type) => (
              <div className={styles.category} key={type.id}>
                {type.name}
              </div>
            ))}
            {data?.isNew && <div className={styles.new}>New</div>}
          </div>

          <div className={styles.title}>{data?.name}</div>
          <div className={styles.text}>{data?.info}</div>

          <div className={styles.details}>
            <div className={styles.files}>
              {data?.fileCount} psd-
              {Decline(data?.fileCount ?? 0, ["файл", "файла", "файлов"])}
            </div>
            {data?.priceType === PriceFilterModel.Free ? (
              <div className={styles.tier}>Free</div>
            ) : (
              <div className={`${styles.tier} ${styles.premium}`}>
                Premium <img src={`${process.env.REACT_APP_BASE_URL}/img/icons/premium.svg`} alt="premium"></img>
              </div>
            )}
          </div>
          {showAuthMessage ? (
            <div className={styles["signup-text"]}>
              <Link to={`/login`} className={styles["signup-link"]} state={{returnUrl:`/mockup/${data?.id}`}}>Войдите</Link>, чтобы скачать. 
            </div>
          ) : (
            <div className={styles.button}>
              <Button {...{ onclick: handleDownloadFile, isSubmit: false, classes: styles["white-button"], disabled: isLoading }}>
                {isLoading ? "Загрузка..." : "Скачать"}
                <img src={`${process.env.REACT_APP_BASE_URL}/img/icons/download.svg`} alt="download" ></img>
              </Button>
            </div>
          )}
          {/* <div className={styles.disclaimer}>
            Файл будет отправлен на ваш почтовый ящик и доступен в личном
            кабинете.
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default DetailedMockup;
