import { FocusEvent, useEffect, useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import validatePasswordString from "../../helpers/PasswordHelper";
import authService from "../../services/authService";
import Button from "../ui/Button";
import styles from "./ConfirmPasswordReset.module.css";

const ConfirmPasswordReset = () => {
  const navigate = useNavigate();
  const [password, setPassword] = useState<string>("");
  const [passwordIsValid, setPasswordIsValid] = useState<boolean>(true);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [isSubmitted, setIsSubmitted] = useState<boolean>(false);

  if (authService.authenticated()) {
    navigate("/");
  }

  useEffect(() => {
    if(isSubmitted){
      setTimeout(() => {
        navigate("/login")
      }, 5000);
    }
  }, [isSubmitted]);

  const { userId } = useParams();
  let [searchParams, setSearchParams] = useSearchParams();
  const token = searchParams.get("token") || "";

  const validatePassword = (event: FocusEvent<HTMLInputElement>) => {
    var value = event.target.value;
    if (!validatePasswordString(value)) {
      setPasswordIsValid(false);
      return;
    }
    setPasswordIsValid(true);
    setPassword(value.trim());
  };

  const submitForm = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (!passwordIsValid) return;

    setErrorMessage(null);

    try {
      await authService.confirmPasswordReset(userId!, token, password);
      setIsSubmitted(true);

    } catch (err) {
      console.error(err);
      setErrorMessage("Убедитесь в правильности ввода пароля: 8 символов, цифры, большие буквы, маленькие буквы.");
    }
  };

  if (isSubmitted) {
    return <div>
       <div className={styles.header}>Пароль был успешно изменен!</div>
       <div className={styles.confirm}>Вы будете перенаправлены на страницу для входа.</div>
    </div>;
  }

  return (
    <div>
      <div className={styles.header}>Сбросить пароль</div>
      <form className={styles.form} onSubmit={submitForm}>
        <div className={[styles.formgroup, styles.vertical].join(" ")}>
          <label
            className={[
              styles.label,
              !passwordIsValid ? styles["label-invalid"] : null,
            ].join(" ")}
            htmlFor="password"
          >
            Новый пароль
          </label>
          <input
            className={[
              styles.input,
              !passwordIsValid ? styles["input-invalid"] : null,
            ].join(" ")}
            id="username"
            type="password"
            placeholder="От 8 символов, цифры, большие и маленькие буквы"
            tabIndex={1}
            onBlur={validatePassword}
            onChange={validatePassword}
            required
          />
        </div>
        <div className={[styles.formgroup, styles.vertical].join(" ")}>
          <div
            className={[
              styles.error,
              errorMessage === null ? null : styles.visible,
            ].join(" ")}
          >
            {errorMessage}
          </div>
          <div className={styles.buttons}>
            <Button
              {...{
                onclick: () => {},
                classes: styles["orange-button"],
                isSubmit: true,
                tabIndex: 3,
              }}
            >
              Изменить пароль
            </Button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default ConfirmPasswordReset;
