import qs from "qs";
import tokenService from "./tokenService";
import api from "./api";
import TokenResponse from "../types/api/TokenResponse";

class AuthService {
  async login(email: string, returnUrl: string) {
    const data = {
      email: email,
      returnUrl: returnUrl ?? "/",
    };
    const options = {
      method: "POST",
      // headers: { "content-type": "application/x-www-form-urlencoded" },
      data,
      url: "/v1/user/register",
    };
    return await api(options);
  }

  logout() {
    tokenService.removeToken();
  }

  // выпилить
  async register(username: string, email: string, password: string) {

    const token = localStorage.getItem("token");

    const headers = {
      Authorization: `Bearer ${token}`
    };
    return await api.post(
      "/v1/user/register", 
      {
        username: username,
        email: email,
        password: password,
      },
      { headers }
    );
  }

  async confirmEmail(userId: string, token: string) {
    const {data} = await api.post<TokenResponse>(`/v1/user/${userId}/confirm-email?token=${token}`)
    tokenService.setToken(data.accessToken)
    // .then((data) => {
    //   tokenService.setToken(data.data.accessToken);
    // });
  }

  async forgotPassword(email: string) {
    return await api.post("/v1/user/forgot-password/init?email=" + email);
  }

  async confirmPasswordReset(userId: string, token: string, newPassword: string) {
    return await api.post(`/v1/user/${userId}/forgot-password/confirm`,{
      newPassword: newPassword,
      token: token
    });
  }

  authenticated() {
    const user = tokenService.getCurrentUser();
    if (user && user.access_token) return true;

    return false;
  }
}

export default new AuthService();
