import {
  ChangeEventHandler,
  FocusEvent,
  FormEventHandler,
  useState,
} from "react";
import styles from "./Contacts.module.css";
import Button from "../ui/Button";
import contactService from "../../services/contactService";

const Contacts = () => {
  const [contactName, setContactName] = useState<string>("");
  const [contactNameIsValid, setContactNameIsValid] = useState<boolean>(true);
  const [contactEmail, setContactEmail] = useState<string>("");
  const [contactEmailIsValid, setContactEmailIsValid] = useState<boolean>(true);
  const [contactMessage, setContactMessage] = useState<string>("");
  const [contactMessageIsValid, setContactMessageIsValid] = useState<boolean>(true);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [isSent, setIsSent] = useState<boolean>(false);

  const validateContactName = (event: FocusEvent<HTMLInputElement>) => {
    var value = event.target.value;
    if (!value || value.trim().length === 0) {
      setContactNameIsValid(false);
      return;
    }
    setContactNameIsValid(true);
    setContactName(value.trim());
  };

  const validateContactEmail = (event: FocusEvent<HTMLInputElement>) => {
    var value = event.target.value;
    if (!value || value.trim().length === 0) {
      setContactEmailIsValid(false);
      return;
    }
    setContactEmailIsValid(true);
    setContactEmail(value.trim());
  };

  const validateContactMessage = (event: FocusEvent<HTMLTextAreaElement>) => {
    var value = event.target.value;
    if (!value || value.trim().length === 0) {
      setContactMessageIsValid(false);
      return;
    }
    setContactMessageIsValid(true);
    setContactMessage(value.trim());
  };

  const submitForm = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (!contactNameIsValid || !contactEmailIsValid || !contactMessageIsValid)
      return;

    try {
      await contactService.sendContactData(
        contactName,
        contactEmail,
        contactMessage
      );
      setIsSent(true);
    } catch (err) {
      setErrorMessage("Что-то пошло не так.");
      console.error(err);
    }
  };

  return (
    <div>
      <h1 className={styles.header}>Контакты</h1>
      <div className={styles.contacts_flex_block}>
        <div className={styles.accompanying_text}>
          <p className={styles.accompanying_text_first}>
            Звоните, пишите, поговорим.
          </p>
        </div>
        <div className={styles.contacts_text}>
          <p className={styles.our_email}>hello@dizup.ru</p>
        </div>
      </div>
      <h2 className={styles.sub_header}>Спрашивайте</h2>
      <div className={styles.contacts_flex_block}>
        <div className={styles.accompanying_text}>
          <p className={styles.accompanying_text_second}>
            Вопросы по организации, сотрудничеству и вообще что угодно.
          </p>
        </div>
        <form
          className={[styles.form, isSent ? styles.hidden : null].join(" ")}
          onSubmit={submitForm}
        >
          <div className={styles.formgroup_names}>
            <label
              className={[
                styles.label,
                !contactNameIsValid ? styles["label-invalid"] : null,
              ].join(" ")}
              htmlFor="contactname"
            >
              Имя
            </label>
            <label
              className={[
                styles.label,
                !contactEmailIsValid ? styles["label-invalid"] : null,
              ].join(" ")}
              htmlFor="e-mail"
            >
              E-mail
            </label>
            <label
              className={[
                styles.label,
                !contactMessageIsValid ? styles["label-invalid"] : null,
              ].join(" ")}
              htmlFor="message"
            >
              Сообщение
            </label>
          </div>
          <div className={styles.inputs}>
            <input
              className={[
                styles.input,
                !contactNameIsValid ? styles["input-invalid"] : null,
              ].join(" ")}
              id="contactname"
              placeholder="Поле для имени"
              tabIndex={1}
              onBlur={validateContactName}
              onChange={validateContactName}
              required
            />
            <input
              className={[
                styles.input,
                !contactEmailIsValid ? styles["input-invalid"] : null,
              ].join(" ")}
              id="contactemail"
              placeholder="Поле для почты"
              tabIndex={2}
              onBlur={validateContactEmail}
              onChange={validateContactEmail}
              required
            />
            <textarea
              className={[
                styles.textarea,
                !contactMessageIsValid ? styles["input-invalid"] : null,
              ].join(" ")}
              id="contactmessage"
              placeholder="Тут можно писать что хочешь..."
              tabIndex={3}
              onBlur={validateContactMessage}
              onChange={validateContactMessage}
              required
            />
          </div>
          <div
            className={[
              styles.error,
              errorMessage === null ? null : styles.visible,
            ].join(" ")}
          >
            {errorMessage}
          </div>
          <Button
            {...{
              onclick: () => {},
              classes: styles["orange-button"],
              isSubmit: true,
              tabIndex: 4,
            }}
          >
            Отправить
          </Button>
        </form>
        <div className={styles.contacts_text}>
          <p
            className={[styles.sent_result, isSent ? null : styles.hidden].join(
              " "
            )}
          >
            Спасибо! <br /> Ваше сообщение в пути.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Contacts;
