import UserShortProfile from "../types/api/UserShortProfile";
import api from "./api";

class UserService {
  async getShortProfile() {
    var { data } = await api.get<UserShortProfile>("/v1/user/short-profile");

    return data;
  }

  async updateProfile(currentPassword: string, newPassword: string) {
    return await api.put(
      "/v1/user",
      {
        currentPassword,
        newPassword,
      }
    );
  }
}

export default new UserService();
