import { MockupModel, MockupTier } from "../../types/MockupModel";
import styles from "./Mockup.module.css";
import Decline from "../../helpers/DeclentionHelper";
import pictureService from "../../services/pictureService";
import { Link, useNavigate } from "react-router-dom";
import Button from "../ui/Button";
import { useEffect, useState } from "react";
import PatternFullModel from "../../types/api/PatternFullModel";
import patternService from "../../services/patternService";
import authService from "../../services/authService";

interface MockupProps {
  mockup: MockupModel;
}


const Mockup = (props: MockupProps) => {

  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [data, setData] = useState<PatternFullModel>();
  const [showAuthMessage, setShowAuthMessage] = useState<boolean>(false);

  const handleDownloadFile = async() => {
    if(data == null)
      return;
    setIsLoading(true);
    await patternService.getPatternFile(data.id, data.name);
    setIsLoading(false);
  };

  useEffect(() => {
    const getTypes = async () => {
      const mockup = await patternService.getFullPattern(props.mockup.id!);
      setData(mockup);
      setIsLoading(false);
      if (!authService.authenticated()) {
        setShowAuthMessage(true);
      }
    };

    getTypes().catch((e) => {
      console.error(e);
      navigate("/");
    });
  },[]);

  return (
    <div className={styles.mockup}>
      <Link to={`/mockup/${props.mockup.id}`}>
        <div
          className={styles.img}
          style={{
            backgroundImage: `url(${pictureService.getPictureUrl(
              props.mockup.imgLink
            )})`,
          }}
        >
          <div className={styles.categories}>
            {props.mockup.categoryNames.map((category) => (
              <div className={styles.category} key={category}>
                {category}
              </div>
            ))}
          </div>
          {props.mockup.isNew && <div className={styles.new}>New</div>}
        </div>
      </Link>
      <div className={styles.info}>
        <Link to={`/mockup/${props.mockup.id}`}>
          <div className={styles.name}>{props.mockup.name}</div>
        </Link>
        {props.mockup.tier === MockupTier.Free ? (
          <div className={styles.tier}>Free</div>
        ) : (
          <div className={`${styles.tier} ${styles.premium}`}>
            Premium <img src={`${process.env.REACT_APP_BASE_URL}/img/icons/premium.svg`}></img>
          </div>
        )}
        <div className={styles.files}>
          {props.mockup.fileCount} psd-
          {Decline(props.mockup.fileCount, ["файл", "файла", "файлов"])}
        </div>
        {props.mockup.tier === MockupTier.Free ? (
          <div>
            {showAuthMessage ? (
             null
            ) : (
              <button 
                className={styles.get} 
                onClick={() => handleDownloadFile()}
                type="button"
                disabled={isLoading}
              >
              <img src={`${process.env.REACT_APP_BASE_URL}/img/icons/download.svg`}></img>
              </button>
            )}
          </div>
        ) : (
          <button className={styles.get}>
            <img src={`${process.env.REACT_APP_BASE_URL}/img/add-to-basket.svg`}></img>
          </button>
        )}
      </div>
    </div>
  );
};

export default Mockup;
