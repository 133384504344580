import "./App.css";
import {
  Routes,
  Route,
  useNavigate,
} from "react-router-dom";
import Header from "./components/ui/Header";
import Footer from "./components/ui/Footer";
import Home from "./components/routes/Home";
import About from "./components/routes/About";
import DetailedMockup from "./components/mockup/DetailedMockup";
import Login from "./components/routes/Login";
import authService from "./services/authService";
import EventBus from "./components/common/EventBus";
import { Fragment, useEffect } from "react";
import ScrollToTop from "./components/common/ScrollToTop";
import Register from "./components/routes/Register";
import ConfirmEmail from "./components/routes/ConfirmEmail";
import Profile from "./components/routes/Profile";
import ForgotPassword from "./components/routes/ForgotPassword";
import ConfirmPasswordReset from "./components/routes/ConfirmPasswordReset";
import Contacts from "./components/routes/Contacts";

function App() {
  const navigate = useNavigate();
  const logout = (): void => {
    authService.logout();
    navigate("/login");
  };

  useEffect(() => {
    EventBus.on("logout", logout);

    // returned function will be called on component unmount
    return () => {
      EventBus.remove("logout", logout);
    };
  }, []);

  return (
    <Fragment>
      {/* <AuthVerify logout={logout} /> */}
      <ScrollToTop />
      <div className="App">
        <Header />
        <Routes>
          <Route path="/" element={<Home />}></Route>
          <Route path="/mockup/:id" element={<DetailedMockup />} />
          <Route path="/about" element={<About />} />
          <Route path="/contacts" element={<Contacts />} />
          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Register />} />
          <Route path="/confirm-email/:userId" element={<ConfirmEmail />} />
          <Route path="/profile" element={<Profile />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/reset-password/:userId" element={<ConfirmPasswordReset />} />
          {/*
        <Route exact path="/faq" element={<About />}></Route>
        <Route exact path="/contact" element={<Contact />}></Route> */}
        </Routes>
        <Footer />
      </div>
    </Fragment>
  );
}

export default App;
