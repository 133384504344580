import PagedResponse from "../types/api/PagedResponse";
import PatternShortModel from "../types/api/PatternShortModel";
import ProductType from "../types/api/ProductType";
import QueryString from "qs";
import PatternFullModel from "../types/api/PatternFullModel";
import api from "./api";

class PatternService {
  async getPatterns(
    productTypes: string[],
    pageNumber: number,
    pageSize: number
  ): Promise<PagedResponse<PatternShortModel>> {
    var { data } = await api.get<PagedResponse<PatternShortModel>>(
      "/v1/pattern",
      {
        params: {
          productTypes: productTypes,
          pageNumber: pageNumber,
          pageSize: pageSize,
        },
        paramsSerializer: params => {
          return QueryString.stringify(params)
        }
      }
    );

    return data;
  }

  async getFullPattern(id: string) : Promise<PatternFullModel>{
    var {data} = await api.get<PatternFullModel>(
      "/v1/pattern/" + id
    );
    
    return data;
  }

  async getTypes(): Promise<ProductType[]> {
    var { data } = await api.get<ProductType[]>(
      "/v1/productType"
    );
    return data;
  }

  getPatternFileUrl(patternId: string) : string {
    return process.env.REACT_APP_API_URL + "/v1/media/file/" + patternId;
  }
  
  async getPatternFile(patternId: string, fileName: string) {
    await api.get<Blob>(this.getPatternFileUrl(patternId), {responseType: 'blob'}).then((response) => {
      const temp = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = temp;
      link.setAttribute("download", fileName + ".zip");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(temp);
    });
  }
}

export default new PatternService();
