import { FocusEvent, useEffect, useState } from "react";
import authService from "../../services/authService";
import { useLocation, useNavigate } from "react-router-dom";
import styles from "./Login.module.css";
import Button from "../ui/Button";
import validateEmail from "../../helpers/EmailHelper";

const Login = () => {
  const navigate = useNavigate();
  const location = useLocation() as any;
  const { returnUrl } = location?.state ?? {};
  const [username, setUsername] = useState<string>("");
  const [usernameIsValid, setUsernameIsValid] = useState<boolean>(true);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [isSubmitted, setIsSubmitted] = useState<boolean>(false);
  let timeOutId: NodeJS.Timeout;



  useEffect(() => {}, [isSubmitted]);
  useEffect(() => {
    if (authService.authenticated()) {
      navigate("/");
    }
  }, [])

  if (isSubmitted) {
    return <div>
       <div className={styles.header}>Пройдите по ссылке из письма</div>
       <div className={styles["login-confirm"]}>Подтвердите свою почту, перейдя по ссылке, которую мы отправили вам в письме.</div>
    </div>;
  }

  const validateUsername = (event: FocusEvent<HTMLInputElement>) => {
    timeOutId = setTimeout(() => {
      var value = event.target.value;
      if (!value || value.trim().length === 0 || !validateEmail(value)) {
        setUsernameIsValid(false);
        return;
      }
      setUsernameIsValid(true);
      setUsername(value.trim());
    }, 500);
    return () => clearTimeout(timeOutId);
  };

  const submitForm = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (!usernameIsValid) return;

    try {
      await authService.login(username, returnUrl);
      // EventBus.dispatch("login");  
      // navigate("/confirm-email");
      setIsSubmitted(true);
    } catch (err) {
      console.error(err);
      setErrorMessage("Возникла непредвиденная ошибка");
    }
  };

  return (
    <div>
      <div className={styles.header}>Вход</div>
      <form className={styles.form} onSubmit={submitForm}>
        <div className={[styles.formgroup, styles.vertical].join(" ")}>
          <label
            className={[
              styles.label,
              !usernameIsValid ? styles["label-invalid"] : null,
            ].join(" ")}
            htmlFor="username"
          >
            E-mail
          </label>
          <input
            className={[
              styles.input,
              !usernameIsValid ? styles["input-invalid"] : null,
            ].join(" ")}
            id="username"
            // type="email"
            placeholder="Ваша почта"
            tabIndex={1}
            onBlur={validateUsername}
            onChange={validateUsername}
            required
          />
        </div>
        <div className={[styles.formgroup, styles.vertical].join(" ")}>
          <div
            className={[
              styles.error,
              errorMessage === null ? null : styles.visible,
            ].join(" ")}
          >
            {errorMessage}
          </div>
          <div className={styles.buttons}>
            <Button
              {...{
                onclick: () => {},
                classes: styles["orange-button"],
                isSubmit: true,
                tabIndex: 3,
                disabled: !usernameIsValid
              }}
            >
              Войти
            </Button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default Login;
