import { FocusEvent, useEffect, useState } from "react";
import UserShortProfile from "../../types/api/UserShortProfile";
import styles from "./Profile.module.css";
import userService from "../../services/userService";
import Button from "../ui/Button";
import validatePasswordString from "../../helpers/PasswordHelper";
import axios from "axios";
import EventBus from "../common/EventBus";

const Profile = () => {
  const [user, setUser] = useState<UserShortProfile | null>(null);
  const [currentPassword, setCurrentPassword] = useState<string>("");
  const [newPassword, setNewPassword] = useState<string>("");
  const [currentPasswordIsValid, setCurrentPasswordIsValid] =
    useState<boolean>(true);
  const [newPasswordIsValid, setNewPasswordIsValid] = useState<boolean>(true);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [isSubmitted, setIsSubmitted] = useState<boolean>(false);

  useEffect(() => {
    const getUser = async () => {
      const data = await userService.getShortProfile();
      setUser(data);
    };

    getUser();
  }, []);

  if (user === null) return <div></div>;

  const validateCurrentPassword = (event: FocusEvent<HTMLInputElement>) => {
    const value = event.target.value?.trim();
    if (value.length === 0) {
      setCurrentPasswordIsValid(true);
      return;
    }

    if (!validatePasswordString(value)) {
      setCurrentPasswordIsValid(false);
      return;
    }

    setCurrentPasswordIsValid(true);
    setCurrentPassword(value);
  };

  const validateNewPassword = (event: FocusEvent<HTMLInputElement>) => {
    const value = event.target.value?.trim();

    if (value.length === 0) {
      setNewPasswordIsValid(true);
      return;
    }

    if (!validatePasswordString(value)) {
      setNewPasswordIsValid(false);
      return;
    }

    setNewPasswordIsValid(true);
    setNewPassword(value);
  };

  const submitForm = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setIsSubmitted(false);
    if (!currentPasswordIsValid || !newPasswordIsValid) return;

    setErrorMessage(null);

    try {
      await userService.updateProfile(currentPassword, newPassword);
      setIsSubmitted(true);
    } catch (err) {
      if (axios.isAxiosError(err)) {
        const data = err.response?.data as any;
        if (data.status === 400) {
          setErrorMessage(
            "Убедитесь в правильности текущего и нового паролей: 8 символов, цифры, большие буквы, маленькие буквы"
          );
        }
      } else {
        console.error(err);
      }
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <div className={styles.username}>{user.userName}</div>
        <Button
          {...{
            onclick: () => {
              EventBus.dispatch("logout");
            },
            classes: styles["small-button"]
          }}
        >
          Выйти
        </Button>
      </div>
      <div className={styles.menu}>
        <div className={[styles["menu-item"], styles.active].join(" ")}>
          Личная информация
        </div>
      </div>
      <form className={styles.form} onSubmit={submitForm}>
        <div className={styles.formgroup}>
          <label className={styles.label} htmlFor="email">
            E-mail
          </label>
          <input
            className={styles.input}
            id="email"
            placeholder="Ваша почта"
            disabled
            value={user.email}
          />
        </div>
        {/* <div className={styles.formgroup}>
          <label className={styles.label} htmlFor="currentPassword">
            Текущий пароль
          </label>
          <input
            className={[
              styles.input,
              !currentPasswordIsValid ? styles["input-invalid"] : null,
            ].join(" ")}
            id="currentPassword"
            placeholder="Текущий пароль"
            type="password"
            tabIndex={2}
            onBlur={validateCurrentPassword}
            onChange={validateCurrentPassword}
          />
        </div>
        <div className={styles.formgroup}>
          <label className={styles.label} htmlFor="newPassword">
            Новый пароль
          </label>
          <input
            className={[
              styles.input,
              !newPasswordIsValid ? styles["input-invalid"] : null,
            ].join(" ")}
            id="newPassword"
            placeholder="От 8 символов, цифры, БОЛЬШИЕ и маленькие буквы"
            type="password"
            tabIndex={3}
            onBlur={validateNewPassword}
            onChange={validateNewPassword}
          />
        </div>
        <div className={styles.formgroup}>
          <div
            className={[
              styles.error,
              errorMessage === null ? null : styles.visible,
            ].join(" ")}
          >
            {errorMessage}
          </div>
          <div
            className={[
              styles.success,
              isSubmitted === false ? null : styles.visible,
            ].join(" ")}
          >
            Пароль изменен
          </div>
          <Button
            {...{
              onclick: () => {},
              classes: styles["orange-button"],
              isSubmit: true,
              tabIndex: 4,
            }}
          >
            Обновить
          </Button>
        </div> */}
      </form>
    </div>
  );
};

export default Profile;
