import { AxiosRequestConfig } from "axios";
import AuthenticatedUser from "../types/AuthenticatedUser";

class TokenService {
  getCurrentUser(): AuthenticatedUser | null {
    const user = localStorage.getItem("user");
    if (user) {
      return JSON.parse(user);
    } else {
      return null;
    }
  }

  getAuthHeader(): AxiosRequestConfig | null {
    const user = this.getCurrentUser();

    if (user && user.access_token) {
      const config: AxiosRequestConfig = {
        headers: {
          Authorization: "Bearer " + user.access_token,
        },
      };
      return config;
    } else {
      return null;
    }
  }

  removeToken(){
    localStorage.removeItem("user");
  }

  setUser(user: string){
    localStorage.setItem("user", user);
  }

  setToken(accessToken: string){
    var user = {
      access_token: accessToken
    }
    localStorage.setItem("user", JSON.stringify(user));
  }
}

export default new TokenService();