import { Fragment } from "react";
import Store from "../mockup/Store";
import Banner from "../ui/Banner";

const Home = () => {
  return (
    <Fragment>
      <Banner>Мокапы для ваших проектов в&nbsp;формате PSD</Banner>
      <Store />
    </Fragment>
  );
};

export default Home;
