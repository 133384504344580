import { Link } from "react-router-dom";
import styles from "./Header.module.css";
import authService from "../../services/authService";
import UserShortProfile from "../../types/api/UserShortProfile";
import { useEffect, useState } from "react";
import EventBus from "../common/EventBus";
import userService from "../../services/userService";

const Header = (props: any) => {
  const authenticated = authService.authenticated();
  const [user, setUser] = useState<UserShortProfile | null>(null);

  const getUser = () => {
    userService
      .getShortProfile()
      .then((data) => {
        setUser(data);
      })
  };

  const removeUser = () => {
    setUser(null);
  }

  useEffect(() => {
    EventBus.on("login", getUser);
    EventBus.on("logout", removeUser);

    // returned function will be called on component unmount
    return () => {
      EventBus.remove("login", getUser);
      EventBus.remove("logout", removeUser);
    };
  }, []);

  if (authenticated && !user) {
    getUser();
  }

  return (
    <header className={styles.header}>
      <div className={styles.logo}>
        <Link to="/">
          <img src={`${process.env.REACT_APP_BASE_URL}/img/logo.svg`} alt="logo"></img>
        </Link>
      </div>
      <div className={styles.menu}>
        <Link className={styles["menu-item"]} to="/about">
          О проекте
        </Link>
        <a className={styles["menu-item"]} href="">
          FAQ
        </a>
        <Link className={styles["menu-item"]} to="/contacts">
          Контакты
        </Link>
        {/* <div className={styles["menu-divider"]} /> */}
        {/* <a className={styles["menu-item"]} href="">
          Стать автором
        </a> */}
      </div>
      <div className={styles.user}>
        {user ? (
          <Link className={styles.login} to="/profile">{user.userName}</Link>
        ) : (
          <Link className={styles.login} to="/login">
            Войти
            <img src={`${process.env.REACT_APP_BASE_URL}/img/icons/login.svg`} alt="login"></img>
          </Link>
        )}

        {/* <a className={styles.basket} href="/basket">
          <img src={`${process.env.REACT_APP_BASE_URL}/img/icons/basket.svg`} alt="basket"></img>
        </a> */}
      </div>
    </header>
  );
};

export default Header;
