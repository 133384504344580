export enum MockupTier {
  Free = 1,
  Premium = 2
}

export interface MockupModel {
  id: string,
  name: string,
  tier: MockupTier,
  fileCount: number,
  imgLink: string,
  categoryNames: string[],
  isNew: boolean
}
