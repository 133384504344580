import api from "./api";

class ContactService {
  async sendContactData(
    contactName: string, 
    contactEmail: string, 
    contactMessage: string
  ) {

    const token = localStorage.getItem("token");

    const headers = {
      Authorization: `Bearer ${token}`
    };

    return await api.post(
      "/v1/contact/add",
      {
        contactName,
        contactEmail,
        contactMessage
      },
      { headers }
    );
  }
}

export default new ContactService();