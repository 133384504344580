import { FocusEvent, useEffect, useState } from "react";
import authService from "../../services/authService";
import { Link, useNavigate } from "react-router-dom";
import styles from "./Register.module.css";
import Button from "../ui/Button";
import axios, { AxiosError } from "axios";
import validatePasswordString from "../../helpers/PasswordHelper";

const Register = () => {
  const navigate = useNavigate();
  const [username, setUsername] = useState<string>("");
  const [usernameIsValid, setUsernameIsValid] = useState<boolean>(true);
  const [password, setPassword] = useState<string>("");
  const [passwordIsValid, setPasswordIsValid] = useState<boolean>(true);
  const [password2, setPassword2] = useState<string>("");
  const [password2IsValid, setPassword2IsValid] = useState<boolean>(true);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [isSubmitted, setIsSubmitted] = useState<boolean>(false);
  let timeOutId: NodeJS.Timeout;

  if (authService.authenticated()) {
    navigate("/");
  }

  useEffect(() => {}, [isSubmitted]);

  if (isSubmitted) {
    return <div>
       <div className={styles.header}>Регистрация почти завершена!</div>
       <div className={styles["register-confirm"]}>Подтвердите свою почту, перейдя по ссылке, которую мы отправили вам в письме.</div>
    </div>;
  }

  const validateUsername = (event: FocusEvent<HTMLInputElement>) => {
    timeOutId = setTimeout(() => {
      var value = event.target.value;
      if (!value || value.trim().length === 0) {
        setUsernameIsValid(false);
        return;
      }
      setUsernameIsValid(true);
      setUsername(value.trim());
    }, 500);
    return () => clearTimeout(timeOutId);
  };

  const validatePassword = (event: FocusEvent<HTMLInputElement>) => {
    const value = event.target.value?.trim();

    if (!validatePasswordString(value)) {
      setPasswordIsValid(false);
      return;
    }

    setPasswordIsValid(true);
    setPassword(value);
  };

  const validatePassword2 = (event: FocusEvent<HTMLInputElement>) => {
    var value = event.target.value?.trim();
    if (!validatePasswordString(value)) {
      setPassword2IsValid(false);
      return;
    }
    setPassword2IsValid(true);
    setPassword2(value);
  };

  const submitForm = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (!usernameIsValid || !passwordIsValid || !password2IsValid) return;
      
    if (password !== password2) {
      setErrorMessage("Убедитесь, что пароли совпадают");
      return;
    }
    setErrorMessage(null);

    try {
      await authService.register(username, username, password);
      setIsSubmitted(true);
    } catch (err) {
      if (axios.isAxiosError(err)) {
        const data = err.response?.data as any;
        if (data.status === 400 && data.detail.includes("already taken")) {
          setErrorMessage("Пользователь c такой почтой уже существует!");
        } else {
          setErrorMessage(
            "Убедитесь в правильности пароля: 8 символов, цифры, большие буквы, маленькие буквы"
          );
        }
      } else {
        console.error(err);
      }
    }
  };

  return (
    <div>
      <div className={styles.header}>Регистрация</div>
      <form className={styles.form} onSubmit={submitForm}>
        <div className={[styles.formgroup, styles.vertical].join(" ")}>
          <label
            className={[
              styles.label,
              !usernameIsValid ? styles["label-invalid"] : null,
            ].join(" ")}
            htmlFor="username"
          >
            E-mail
          </label>
          <input
            className={[
              styles.input,
              !usernameIsValid ? styles["input-invalid"] : null,
            ].join(" ")}
            id="username"
            // type="email"
            placeholder="Ваша почта"
            tabIndex={1}
            onBlur={validateUsername}
            onChange={validateUsername}
            required
          />
        </div>
        <div className={[styles.formgroup, styles.vertical].join(" ")}>
          <label
            className={[
              styles.label,
              !passwordIsValid ? styles["label-invalid"] : null,
            ].join(" ")}
            htmlFor="password"
          >
            Пароль
          </label>
          <input
            className={[
              styles.input,
              !passwordIsValid ? styles["input-invalid"] : null,
            ].join(" ")}
            id="password"
            type="password"
            placeholder="От 8 символов, цифры, большие и маленькие буквы"
            tabIndex={2}
            onBlur={validatePassword}
            onChange={validatePassword}
            required
            minLength={8}
          />
        </div>
        <div className={[styles.formgroup, styles.vertical].join(" ")}>
          <label
            className={[
              styles.label,
              !password2IsValid ? styles["label-invalid"] : null,
            ].join(" ")}
            htmlFor="password2"
          >
            И еще раз пароль
          </label>
          <input
            className={[
              styles.input,
              !password2IsValid ? styles["input-invalid"] : null,
            ].join(" ")}
            id="password2"
            type="password"
            placeholder="Такой же, как как и выше"
            tabIndex={3}
            onBlur={validatePassword2}
            onChange={validatePassword2}
            required
            minLength={8}
          />
        </div>
        <div className={[styles.formgroup, styles.vertical].join(" ")}>
          <div
            className={[
              styles.error,
              errorMessage === null ? null : styles.visible,
            ].join(" ")}
          >
            {errorMessage}
          </div>
          <div className={styles.buttons}>
            <Button
              {...{
                onclick: () => {},
                classes: styles["orange-button"],
                isSubmit: true,
                tabIndex: 4,
              }}
            >
              Зарегистрироваться
            </Button>
            <div>
              Уже есть аккаунт?
              <Link to={"/login"} className={styles.login}>
                Войти
              </Link>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default Register;
