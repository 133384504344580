import styles from "./MockupList.module.css";
import { MockupModel } from "../../types/MockupModel";
import Mockup from "./Mockup";

interface MockupListProps {
  mockups: MockupModel[];
}

const MockupList = (props: MockupListProps) => {
  return (
    <div className={styles["container"]}>
      <div className={styles["mockup-list"]}>
        {props.mockups.map((mockup) => (
          <Mockup mockup={mockup} key={mockup.id} />
        ))}
      </div>
    </div>
  );
};

export default MockupList;
