const EventBus = {
  on(event: string, callback: (e: any) => void) {
    document.addEventListener(event, (e) => callback((<CustomEvent>e).detail));
  },
  dispatch(event: string, data: any | null = null) {
    document.dispatchEvent(new CustomEvent(event, { detail: data }));
  },
  remove(event: string, callback: (e: any) => void) {
    document.removeEventListener(event, callback);
  },
};
export default EventBus;
