import { Link } from "react-router-dom";
import styles from "./Footer.module.css";

const Footer = () => {
  return (
    <div className={styles.footer}>
      <div className={styles.logo}>
        <Link to="/">
          <img src={`${process.env.REACT_APP_BASE_URL}/img/logo.svg`} alt="logo"></img>
        </Link>
      </div>
      <div className={styles.content}>
        <div className={styles.social}>
          <div>Мы на связи</div>
          <div>
            <a href="https://vk.com/dizupru" target="_blank">
              <img src={`${process.env.REACT_APP_BASE_URL}/img/icons/vk.svg`} alt="vk"></img>
            </a>
            <a href="https://t.me/dizupru" target="_blank">
              <img src={`${process.env.REACT_APP_BASE_URL}/img/icons/telegram.svg`} alt="telegram"></img>
            </a>
          </div>
        </div>
        <div className={styles.about}>
          <Link to="/about">О проекте</Link>
          <a href="">FAQ</a>
          <a href="">Контакты</a>
        </div>
        {/* <div className={styles.misc}>
          <div><a href="">Найти мокап</a></div>
          <div><a href="">Стать автором</a></div>
        </div> */}
      </div>
    </div>
  );
};

export default Footer;
