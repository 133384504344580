import { useState } from "react";
import ProductTypeModel from "../../types/ProductTypeModel";
import styles from "./Filter.module.css";

interface FilterProps {
  filter: ProductTypeModel;
  onSelect: (id: string, selected: boolean) => void;
}

const Filter = (props: FilterProps) => {
  const [selected, setSelected] = useState(false);

  const onClickHandler = () => {
    var newValue = !selected;
    setSelected(newValue);
    props.onSelect(props.filter.id, newValue);
  };

  return (
    <div
      className={`${styles.filter} ${selected && styles.active}`}
      onClick={onClickHandler}
    >
      {props.filter.name}
    </div>
  );
};

export default Filter;
