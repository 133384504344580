import styles from "./About.module.css";
import { useNavigate } from "react-router-dom";
import Button from "../ui/Button";

const About = () => {
  const navigate = useNavigate();
  return (
    <div className={styles.container}>
      <div className={styles.title}>О проекте</div>
      <div className={styles.description}>
        <div className={styles["description-text"]}>
          <strong>dizup.ru</strong> – это площадка на которой вы можете... А
          впрочем как будто вы не понимаете что вы можете тут найти.
        </div>
        <div className={styles["description-buttons"]}>
          <Button
            {...{
              onclick: () => {
                navigate("/");
              },
            }}
          >
            Смотреть мокапы&nbsp;&nbsp;<img src={`${process.env.REACT_APP_BASE_URL}/img/icons/arrow.svg`}></img>
          </Button>
{/*           <Button
            {...{
              onclick: () => {},
            }}
          >
            Стать автором&nbsp;&nbsp;<img src={`${REACT_APP_BASE_URL}/img/icons/arrow.svg`}></img>
          </Button> */}
        </div>
      </div>
      <div className={styles.team}>
        <div className={styles["team-title"]}>Команда</div>
        <div className={styles["team-description"]}>
          Несколько важных людей.
        </div>
        <div className={styles["team-names"]}>
          <div className={styles["person"]}>
            <div className={styles["photo"]}>
              <div className={styles["photo-kalyaki"]}>
                <img src={`${process.env.REACT_APP_BASE_URL}/img/icons/kalyaki-1.svg`}></img>
              </div>
              <img src={`${process.env.REACT_APP_BASE_URL}/img/ekaterina.png`}></img>
            </div>
            <div className={styles["person-info"]}>
              <div className={styles["person-name"]}>Екатерина</div>
              <div className={styles["person-description"]}>
                Главная по главному
              </div>
              <div className={styles["person-contact"]}>
                <a target="_blank" href="https://tg.me">
                  <img src={`${process.env.REACT_APP_BASE_URL}/img/icons/person-tg.png`}></img>
                </a>
              </div>
            </div>
          </div>

          <div className={styles["person"]}>
            <div className={styles["photo"]}>
              <div className={styles["photo-kalyaki"]}>
                <img src={`${process.env.REACT_APP_BASE_URL}/img/icons/kalyaki-2.svg`}></img>
              </div>
              <img src={`${process.env.REACT_APP_BASE_URL}/img/sergey.png`}></img>
            </div>
            <div className={styles["person-info"]}>
              <div className={styles["person-name"]}>Сергей</div>
              <div className={styles["person-description"]}>
                Главный по коду
              </div>
              <div className={styles["person-contact"]}>
                <a target="_blank" href="https://tg.me">
                  <img src={`${process.env.REACT_APP_BASE_URL}/img/icons/person-tg.png`}></img>
                </a>
              </div>
            </div>
          </div>

          <div className={styles["person"]}>
            <div className={styles["photo"]}>
              <div className={styles["photo-kalyaki"]}>
                <img src={`${process.env.REACT_APP_BASE_URL}/img/icons/kalyaki-3.svg`}></img>
              </div>
              <img src={`${process.env.REACT_APP_BASE_URL}/img/alexandr.png`}></img>
            </div>
            <div className={styles["person-info"]}>
              <div className={styles["person-name"]}>Александр</div>
              <div className={styles["person-description"]}>
                Не главный, но нужный
              </div>
              <div className={styles["person-contact"]}>
                <a target="_blank" href="https://tg.me">
                  <img src={`${process.env.REACT_APP_BASE_URL}/img/icons/person-tg.png`}></img>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
