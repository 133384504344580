const validatePasswordString = (password: string): boolean => {
  const value = password?.trim();
  if (!value || value.length < 8) {
    return false;
  }

  if (!/\d/.test(value)) {
    // digits
    console.error("digits");
    return false;
  }

  let hasUpperCase = false;
  for (var i = 0; i < value.length; i++) {
    if (
      value.charAt(i) === value.charAt(i).toUpperCase() &&
      (value.charAt(i).match(/[a-z]/i) || value.charAt(i).match(/[а-я]/i))
    ) {
      hasUpperCase = true;
    }
  }
  if (!hasUpperCase) return false;

  let hasLowerCase = false;
  for (var i = 0; i < value.length; i++) {
    if (
      value.charAt(i) === value.charAt(i).toLowerCase() &&
      (value.charAt(i).match(/[a-z]/i) || value.charAt(i).match(/[а-я]/i))
    ) {
      hasLowerCase = true;
    }
  }
  if (!hasLowerCase) return false;

  return true;
};

export default validatePasswordString;
