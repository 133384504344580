import axios from "axios";
import qs from "qs";
import EventBus from "../components/common/EventBus";
import tokenService from "./tokenService";

const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

api.interceptors.request.use(
  (config) => {
    const token = tokenService.getCurrentUser()?.access_token;
    if (token && config.url !== "/connect/token") {
      config.headers!["Authorization"] = "Bearer " + token;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

api.interceptors.response.use(
  (res) => {
    return res;
  },
  async (err) => {
    const originalConfig = err.config;
    if (originalConfig.url !== "/connect/token" && err.response) {
      // Access Token was expired
      if (err.response.status === 401 && !originalConfig._retry) {
        originalConfig._retry = true;
        try {
          const data = {
            refresh_token: tokenService.getCurrentUser()?.refresh_token,
            grant_type: "refresh_token",
            client_id: "js_oauth",
          };
          const options = {
            method: "POST",
            headers: { "content-type": "application/x-www-form-urlencoded" },
            data: qs.stringify(data),
            url: "/connect/token",
          };
          const response = await api(options);
          tokenService.setUser(JSON.stringify(response.data));
          return api(originalConfig);
        } catch (_error) {
          EventBus.dispatch("logout")
          return Promise.reject(_error);
        }
      }
    }
    return Promise.reject(err);
  }
);

export default api;
