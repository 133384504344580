import { useEffect, useState } from "react";
import ProductType from "../../types/api/ProductType";
import { MockupModel, MockupTier } from "../../types/MockupModel";
import PriceFilterModel from "../../types/PriceFilterModel";
import FilterList from "./FilterList";
import MockupList from "./MockupList";
import styles from "./Store.module.css";
import patternService from "../../services/patternService";

const PAGE_SIZE = 12;

const Store = () => {
  const [productTypes, setProductTypes] = useState<ProductType[]>([]);
  const [selectedProductTypes, setSelectedProductTypes] = useState<string[]>(
    []
  );
  const [mockups, setMockups] = useState<MockupModel[]>([]);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number>(0);
  const [showMoreButton, setShowMoreButton] = useState<boolean>(false);

  const getMockups = async (
    productTypes: string[],
    page: number,
    fullReload: boolean = false
  ) => {
    const result = await patternService.getPatterns(
      productTypes,
      page,
      PAGE_SIZE
    );
    const newMockups: MockupModel[] = result.items.map((x) => ({
      id: x.id,
      name: x.name,
      tier: MockupTier.Free,
      fileCount: x.fileCount,
      imgLink: x.pictures[0].largeUrl,
      categoryNames: x.productTypes.map((x) => x.name),
      isNew: x.isNew,
    }));

    if (fullReload === true) {
      setMockups(newMockups);
    } else {
      setMockups((prevMockups) => {
        return [...prevMockups, ...newMockups];
      });
    }

    setCurrentPage(result.currentPage);
    setTotalPages(result.totalPages);
    setShowMoreButton(result.currentPage < result.totalPages);
  };

  const onFiltersChange = (ids: string[]) => {
    setSelectedProductTypes(ids);
    setCurrentPage(1);
    getMockups(ids, 1, true).catch(console.error);
  };

  const onPriceFilterChange = (filter: PriceFilterModel) => {};
  const moreClickHandler = async () => {
    await getMockups(selectedProductTypes, currentPage + 1).catch(
      console.error
    );
  };

  useEffect(() => {
    const getTypes = async () => {
      const types = await patternService.getTypes();
      setProductTypes(types);
    };

    getTypes().catch(console.error);
    getMockups([], currentPage, true).catch(console.error);
  }, []);

  return (
    <div className={styles.store}>
      <FilterList
        {...{ filters: productTypes, onSelectionChange: onFiltersChange }}
      />
      {/* <div className={styles.sorters}>
        <PriceFilter onSelectionChange={onPriceFilterChange} />
      </div> */}
      <MockupList mockups={mockups} />
      {showMoreButton ? (
        <div className={styles["load-more"]}>
          <button onClick={moreClickHandler}>Загрузить еще</button>
        </div>
      ) : null}
    </div>
  );
};

export default Store;
