import { useState } from "react";
import ProductTypeModel from "../../types/ProductTypeModel";
import Filter from "./Filter";
import styles from "./FilterList.module.css";

interface FilterListProps {
  filters: ProductTypeModel[];
  onSelectionChange: (ids: string[]) => void;
}

const FilterList = (props: FilterListProps) => {
  const [selectedFilters, setSelectedFilters] = useState<string[]>([]);
  const onFilterSelect = (id: string, selected: boolean) => {
    const updatedFilters = selected
        ? [...selectedFilters, id]
        : selectedFilters.filter(prevId => prevId !== id);

    setSelectedFilters(updatedFilters);
    props.onSelectionChange(updatedFilters);
  };

  return (
    <div className={styles["filter-list"]}>
      {props.filters.map((filter) => (
        <Filter {...{ filter: filter, onSelect: onFilterSelect, key: filter.id}} />
      ))}
    </div>
  );
};

export default FilterList;
