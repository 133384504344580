import { Fragment, useEffect, useState } from "react";
import authService from "../../services/authService";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import styles from "./ConfirmEmail.module.css";
import Button from "../ui/Button";

const ConfirmEmail = () => {
  const navigate = useNavigate();
  const [isValidationError, setIsValidationError] = useState<boolean>(false);
  const [isValidated, setIsValidated] = useState<boolean>(false);

  const { userId } = useParams();
  let [searchParams, setSearchParams] = useSearchParams();
  const token = searchParams.get("token") || "";
  const returnUrl = searchParams.get("returnUrl") || "/";

  useEffect(() => {
    if (authService.authenticated()) {
      navigate("/");
    }
    
    authService.confirmEmail(userId!, token)
      .then(() => {
        setIsValidated(true);
      })
      .catch((err) => {
        console.error(err);
        setIsValidationError(true);
      });
  }, []);

  return (
    <Fragment>
      {!isValidated && !isValidationError && (
        <div>
          <div className={styles.header}>Подтверждение почты</div>
        </div>
      )}
      {isValidationError && (
        <div>
          <div className={styles.header}>Не удалось подтвердить почту</div>
          <div className={styles["register-confirm"]}>
            Попробуйте еще раз или напишите нам.
          </div>
        </div>
      )}
      {isValidated && (
        <div>
          <div className={styles.header}>Почта подтверждена!</div>
          <div className={styles.description}>
            <div className={styles["description-text"]}>
              Теперь вы можете скачивать мокапы.
            </div>
            <div className={styles["description-buttons"]}>
              <Button
                {...{
                  onclick: () => {
                    navigate(returnUrl);
                  },
                }}
              >
                Вернуться&nbsp;&nbsp;<img src={`${process.env.REACT_APP_BASE_URL}/img/icons/arrow.svg`}></img>
              </Button>
            </div>
          </div>
        </div>
      )}
    </Fragment>
  );
};

export default ConfirmEmail;
