import { FocusEvent, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import authService from "../../services/authService";
import Button from "../ui/Button";
import styles from "./ForgotPassword.module.css";

const ForgotPassword = () => {
  const navigate = useNavigate();
  const [username, setUsername] = useState<string>("");
  const [usernameIsValid, setUsernameIsValid] = useState<boolean>(true);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [isSubmitted, setIsSubmitted] = useState<boolean>(false);
  let timeOutId: NodeJS.Timeout;

  if (authService.authenticated()) {
    navigate("/");
  }

  const validateUsername = (event: FocusEvent<HTMLInputElement>) => {
    timeOutId = setTimeout(() => {
      var value = event.target.value;
      if (!value || value.trim().length === 0) {
        setUsernameIsValid(false);
        return;
      }
      setUsernameIsValid(true);
      setUsername(value.trim());
    }, 500);
    return () => clearTimeout(timeOutId);
  };

  const submitForm = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (!usernameIsValid) return;

    setErrorMessage(null);

    try {
      await authService.forgotPassword(username);
      setIsSubmitted(true);

    } catch (err) {
      console.error(err);
      setErrorMessage("Убедитесь в правильности почты");
    }
  };

  if (isSubmitted) {
    return <div>
       <div className={styles.header}>Ссылка для сброса пароля отправлена</div>
       <div className={styles.confirm}>Подтвердите свою почту, перейдя по ссылке, которую мы отправили вам в письме.</div>
    </div>;
  }
  
  return (
    <div>
      <div className={styles.header}>Сбросить пароль</div>
      <form className={styles.form} onSubmit={submitForm}>
        <div className={[styles.formgroup, styles.vertical].join(" ")}>
          <label
            className={[
              styles.label,
              !usernameIsValid ? styles["label-invalid"] : null,
            ].join(" ")}
            htmlFor="username"
          >
            E-mail
          </label>
          <input
            className={[
              styles.input,
              !usernameIsValid ? styles["input-invalid"] : null,
            ].join(" ")}
            id="username"
            // type="email"
            placeholder="Ваша почта"
            tabIndex={1}
            onBlur={validateUsername}
            onChange={validateUsername}
            required
          />
        </div>
        <div className={[styles.formgroup, styles.vertical].join(" ")}>
          <div
            className={[
              styles.error,
              errorMessage === null ? null : styles.visible,
            ].join(" ")}
          >
            {errorMessage}
          </div>
          <div className={styles.buttons}>
            <Button
              {...{
                onclick: () => {},
                classes: styles["orange-button"],
                isSubmit: true,
                tabIndex: 3,
              }}
            >
              Отправить ссылку
            </Button>
            <div className={styles.links}>
              <Link to={"/login"} className={styles.link}>
                Войти
              </Link>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default ForgotPassword;
