import styles from "./Button.module.css";

interface ButtonProps {
  onclick?: () => void;
  children?: any;
  classes?: string;
  tabIndex?: number;
  isSubmit?: boolean;
  isLoading?: boolean;
}

const Button = (props: ButtonProps) => {
  return (
    <button
      className={`${styles.button} ${props.classes}`}
      onClick={props.onclick ? props.onclick : undefined}
      {...(props.tabIndex ? {tabIndex: props.tabIndex} : {})}
      type={props.isSubmit ? "submit" : "button"}
      disabled={props.isLoading}
    >
      {props.isLoading ? "Загрузка..." : props.children}
    </button>
  );
};

export default Button;
